/* eslint-disable no-restricted-imports */
import { ModalProps } from '@mui/material';
import { useEffect, ReactNode } from 'react';
import { Icon, Drawer, GridFlex, IconButton, LongText, Text } from '@stigg-components';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import isString from 'lodash/isString';
import { useAppDispatch } from '../../redux/store';
import { setIsRightDrawerOpen } from '../../modules/application/applicationSlice';
import { useHideIntercom } from '../StiggIntercom/useHideIntercom';
import { SANDBOX_ENVIRONMENT_BANNER_HEIGHT } from '../layout/SandboxEnvironmentBanner';
import { useAppDrawer } from '../layout/AppLayout';
import useEscKeyAction from '../hooks/onEscKeyNavigation';

const NO_OVERLAY_MODAL_PROPS: Partial<ModalProps> = {
  BackdropProps: {
    style: {
      backgroundColor: 'transparent',
    },
  },
};

type CustomDrawerProps = {
  title?: string | ReactNode;
  pretitle?: string;
  description?: ReactNode | string;
  additionalHeaderComponents?: ReactNode | string;
  width?: number;
  isOpen: boolean;
  onClose: () => void;
  noOverlay?: boolean;
  footer?: ReactNode;
} & Omit<DrawerProps, 'open'>;

export function CustomDrawer({
  title,
  pretitle,
  description,
  width = 600,
  children,
  isOpen,
  onClose,
  noOverlay,
  additionalHeaderComponents,
  footer,
  ...props
}: CustomDrawerProps) {
  useEscKeyAction(onClose);
  const dispatch = useAppDispatch();
  const { isSandboxBannerOpen } = useAppDrawer();
  useHideIntercom(isOpen);

  useEffect(() => {
    void dispatch(setIsRightDrawerOpen({ isOpen, width }));
    return () => {
      void dispatch(setIsRightDrawerOpen({ isOpen: false }));
    };
  }, [dispatch, isOpen, width]);

  const modalProps = noOverlay ? NO_OVERLAY_MODAL_PROPS : undefined;

  return (
    <Drawer
      anchor="right"
      ModalProps={modalProps}
      PaperProps={{
        sx: {
          backgroundColor: (theme) => theme.itamar.palette.background.paper,
          borderLeft: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
          boxShadow: (theme) => theme.itamar.palette.shadow.popover,
          top: isSandboxBannerOpen ? `${SANDBOX_ENVIRONMENT_BANNER_HEIGHT}px` : undefined,
        },
      }}
      open={isOpen}
      onClose={onClose}
      {...props}>
      <GridFlex.Column minWidth={width} maxWidth={width} height="100%" p={6} pb={0}>
        <GridFlex.RowSpaceBetween>
          <GridFlex.Column width="100%" pr={4}>
            {pretitle && <Text.B2 color="secondary">{pretitle}</Text.B2>}
            {title && isString(title) ? <LongText variant="h3">{title}</LongText> : title}
          </GridFlex.Column>
          <GridFlex.Row>
            {additionalHeaderComponents}
            <IconButton aria-label="close" onClick={onClose}>
              <Icon type="materialIcons" icon="Close" color="active" />
            </IconButton>
          </GridFlex.Row>
        </GridFlex.RowSpaceBetween>
        {description && isString(description) ? (
          <Text.B2 color="secondary" my={2}>
            {description}
          </Text.B2>
        ) : (
          description
        )}
        <GridFlex.Item flexGrow={1} mb={footer ? 2 : 6}>
          {children}
        </GridFlex.Item>
        {footer ? <GridFlex.Item>{footer}</GridFlex.Item> : null}
      </GridFlex.Column>
    </Drawer>
  );
}
