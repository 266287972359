import { StiggLogo, Text, Grid, Button, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { Container, FormWrapper, FadedGrid } from './SwitchAccountPage.style';
import { useMembershipsData } from './useMembershipsData';
import { MembershipCard } from './MembershipCard';
import { useNavigation } from '../../../navigation/useNavigation';

function SwitchAccountPage() {
  const memberships = useMembershipsData();
  const { navigateTo } = useNavigation();

  return (
    <Container>
      <FadedGrid />
      <FormWrapper>
        <GridFlex.ColumnMiddle>
          <StiggLogo />
          <Text.H1 $medium color="primary" align="center" my={6}>
            {t('chooseAnAccount')}
          </Text.H1>
        </GridFlex.ColumnMiddle>
        <Grid item container rowSpacing={2} alignItems="center">
          {memberships.map((membership) => (
            <Grid key={membership.account.id} item xl={12}>
              <MembershipCard membership={membership} />
            </Grid>
          ))}
        </Grid>
        <Button mt={6} mb={2} hoverColor="disabled" onClick={() => navigateTo('/')}>
          <Text.B2 color="primary.main">{t('backToStigg')}</Text.B2>
        </Button>
      </FormWrapper>
    </Container>
  );
}

export default SwitchAccountPage;
