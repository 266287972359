import { palette, border } from './itamar';
import { darkPalette, border as darkBorder } from './itamar/itamar.dark';
import { VariantType } from './variants.types';

export enum THEMES {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

const lightVariant: VariantType = {
  name: THEMES.LIGHT,
  itamar: { palette, border },

  header: {
    color: palette.grey[500],
    background: palette.white,
    search: {
      color: palette.grey[800],
    },
    indicator: {
      background: palette.grey[600],
    },
  },
  footer: {
    color: palette.grey[500],
    background: palette.white,
  },
  pageCard: {
    background: palette.white,
  },
  sidebar: {
    color: palette.sidebarText,
    inactiveColor: palette.grey[200],
    background: palette.background.darkBackground2,
    activeBackground: palette.background.fadedBackground,
    header: {
      color: palette.sidebarText,
      background: palette.background.darkBackground2,
      brand: {
        color: palette.white,
      },
    },
  },
  chip: {
    default: palette.grey[300],
    primary: palette.primary.main,
    secondary: palette.secondary.main,
  },
};

const darkVariant: VariantType = {
  name: THEMES.DARK,
  itamar: { palette: darkPalette, border: darkBorder },

  header: {
    color: darkPalette.grey[500],
    background: darkPalette.background.darkBackground2,
    search: {
      color: darkPalette.grey[800],
    },
    indicator: {
      background: darkPalette.grey[600],
    },
  },
  footer: {
    color: darkPalette.grey[500],
    background: darkPalette.white,
  },
  pageCard: {
    background: darkPalette.background.darkBackground,
  },
  sidebar: {
    color: darkPalette.sidebarText,
    inactiveColor: darkPalette.grey[200],
    background: darkPalette.background.darkBackground2,
    activeBackground: darkPalette.background.fadedBackground,
    header: {
      color: darkPalette.sidebarText,
      background: darkPalette.background.darkBackground2,
      brand: {
        color: darkPalette.white,
      },
    },
  },
  chip: {
    default: darkPalette.grey[300],
    primary: darkPalette.primary.main,
    secondary: darkPalette.secondary.main,
  },
};

export default [lightVariant, darkVariant];
