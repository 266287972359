import { COLORS_DARK } from '../colors.dark';
import { ItamarPalette, CustomColor, ItamarBorder } from './itamar.types';

const grey: CustomColor = {
  10: COLORS_DARK['grey.10'],
  25: COLORS_DARK['grey.25'],
  50: COLORS_DARK['grey.50'],
  80: COLORS_DARK['grey.80'],
  100: COLORS_DARK['grey.100'],
  200: COLORS_DARK['grey.200'],
  300: COLORS_DARK['grey.300'],
  400: COLORS_DARK['grey.400'],
  500: COLORS_DARK['grey.500'],
  600: COLORS_DARK['grey.600'],
  700: COLORS_DARK['grey.700'],
  800: COLORS_DARK['grey.800'],
  900: COLORS_DARK['grey.900'],
};

export const darkPalette: ItamarPalette = {
  white: COLORS_DARK.white,
  black: COLORS_DARK.black,
  text: {
    white: COLORS_DARK.white,
    primary: COLORS_DARK['text.primary'],
    secondary: COLORS_DARK['text.secondary'],
    tertiary: COLORS_DARK['text.tertiary'],
    disabled: COLORS_DARK['text.disabled'],
  },
  primary: {
    main: COLORS_DARK['primary.main'],
    dark: COLORS_DARK['primary.dark'],
    light: COLORS_DARK['primary.light'],
    contrastText: COLORS_DARK['primary.contrastText'],
    outlinedRestingBackground: COLORS_DARK['primary.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS_DARK['primary.outlinedHoverBackground'],
    containedHoverBackground: COLORS_DARK['primary.containedHoverBackground'],
  },
  secondary: {
    main: COLORS_DARK['secondary.main'],
    dark: COLORS_DARK['secondary.dark'],
    light: COLORS_DARK['secondary.light'],
    contrastText: COLORS_DARK['secondary.contrastText'],
    outlinedRestingBackground: COLORS_DARK['secondary.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS_DARK['secondary.outlinedHoverBackground'],
    containedHoverBackground: COLORS_DARK['secondary.containedHoverBackground'],
  },
  action: {
    active: COLORS_DARK['action.active'],
    hover: COLORS_DARK['action.hover'],
    selected: COLORS_DARK['action.selected'],
    disabled: COLORS_DARK['action.disabled'],
    disabledBackground: COLORS_DARK['action.disabledBackground'],
    focus: COLORS_DARK['action.focus'],
  },
  error: {
    main: COLORS_DARK['error.main'],
    dark: COLORS_DARK['error.dark'],
    light: COLORS_DARK['error.light'],
    contrastText: COLORS_DARK['error.contrastText'],
    outlinedRestingBackground: COLORS_DARK['error.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS_DARK['error.outlinedHoverBackground'],
    containedHoverBackground: COLORS_DARK['error.containedHoverBackground'],
    content: COLORS_DARK['error.content'],
    background: COLORS_DARK['error.background'],
  },
  warning: {
    main: COLORS_DARK['warning.main'],
    dark: COLORS_DARK['warning.dark'],
    light: COLORS_DARK['warning.light'],
    contrastText: COLORS_DARK['warning.contrastText'],
    outlinedRestingBackground: COLORS_DARK['warning.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS_DARK['warning.outlinedHoverBackground'],
    containedHoverBackground: COLORS_DARK['warning.containedHoverBackground'],
    content: COLORS_DARK['warning.content'],
    background: COLORS_DARK['warning.background'],
  },
  info: {
    main: COLORS_DARK['info.main'],
    dark: COLORS_DARK['info.dark'],
    light: COLORS_DARK['info.light'],
    contrastText: COLORS_DARK['info.contrastText'],
    outlinedRestingBackground: COLORS_DARK['info.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS_DARK['info.outlinedHoverBackground'],
    containedHoverBackground: COLORS_DARK['info.containedHoverBackground'],
    content: COLORS_DARK['info.content'],
    background: COLORS_DARK['info.background'],
  },
  success: {
    main: COLORS_DARK['success.main'],
    dark: COLORS_DARK['success.dark'],
    light: COLORS_DARK['success.light'],
    contrastText: COLORS_DARK['success.contrastText'],
    outlinedRestingBackground: COLORS_DARK['success.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS_DARK['success.outlinedHoverBackground'],
    containedHoverBackground: COLORS_DARK['success.containedHoverBackground'],
    content: COLORS_DARK['success.content'],
    background: COLORS_DARK['success.background'],
  },
  background: {
    paper: COLORS_DARK['background.paper'],
    default: COLORS_DARK['background.default'],
    lightBackground: COLORS_DARK['background.lightBackground'],
    lightBackground2: COLORS_DARK['background.lightBackground2'],
    darkBackground: COLORS_DARK['background.darkBackground'],
    darkBackground2: COLORS_DARK['background.darkBackground2'],
    // TODO: Remove these
    darkBackground3: COLORS_DARK['background.darkBackground3'],
    darkBackground4: COLORS_DARK['background.darkBackground4'],
    darkBackground5: COLORS_DARK['background.darkBackground5'],
    fadedBackground: COLORS_DARK['background.faded'],
    emptyState: COLORS_DARK['background.emptyState'],
    runningFlow: COLORS_DARK['background.runningFlow'],
    sandboxEnvironment: COLORS_DARK['background.sandboxEnvironment'],
  },
  // TODO: remove?
  border: {
    primary: COLORS_DARK['border.primary'],
  },
  other: {
    outlineBorder: COLORS_DARK['other.outlineBorder'],
    outlineBorderLight: COLORS_DARK['other.outlineBorderLight'],
    backdropOverlay: COLORS_DARK['other.backdropOverlay'],
    filledInputBackground: COLORS_DARK['other.filledInputBackground'],
    standardInputLine: COLORS_DARK['other.standardInputLine'],
    snackbarBackground: COLORS_DARK['other.snackbarBackground'],
    ratingActive: COLORS_DARK['other.ratingActive'],
    divider: COLORS_DARK['other.divider'],
    focusBorder: COLORS_DARK['other.focusBorder'],
    containedBackground: COLORS_DARK['other.containedBackground'],
    boldDivider: COLORS_DARK['other.boldDivider'],
  },
  shadow: {
    outlined: COLORS_DARK['shadow.outlined'],
    lightShadow: COLORS_DARK['shadow.lightShadow'],
    popover: COLORS_DARK['shadow.popover'],
    inset: COLORS_DARK['shadow.inset'],
    paper: COLORS_DARK['shadow.paper'],
  },
  iconDefault: COLORS_DARK.iconDefault,
  sidebarText: COLORS_DARK.sidebarText,
  expiredColor: COLORS_DARK.expiredColor,
  grey,
  integrations: {
    stripe: COLORS_DARK.stripe,
    slack: COLORS_DARK.slack,
    hubspot: COLORS_DARK.hubspot,
    awsMarketplace: COLORS_DARK.awsMarketplace,
  },
  environmentSwitch: {
    background: COLORS_DARK['background.darkBackground'],
    hoverBackground: COLORS_DARK['background.darkHover'],
    border: COLORS_DARK['other.fadedBorder'],
  },
};

export const border: ItamarBorder = {
  border: '1px solid',
  radius: '10px',
  // borderColor: darkPalette.shadow.outlined,
  borderColor: darkPalette.border.primary,
};
