// eslint-disable-next-line no-restricted-imports
import { PopoverOrigin, Select as MuiSelect, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import {
  FormControl as MuiFormControl,
  Input,
  SelectProps as MuiSelectProps,
  InformationTooltip,
  Text,
} from '@stigg-components';
import { styled, useStiggTheme } from '@stigg-theme';
import React, { forwardRef, PropsWithChildren } from 'react';
import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import { TooltipFields } from './InformationTooltip';
import { OutlinedFormFieldLabel } from './form/OutlinedFormFieldLabel';
import { ArrowDropDownIcon } from './dropdowns/buttonAndOptionsDropdown/ButtonAndOptionsDropdown.style';

const FormControl = styled(MuiFormControl)<{ $withoutPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  .MuiSvgIcon-root {
    margin-right: ${({ theme, $withoutPadding }) => ($withoutPadding ? 0 : theme.spacing(2))};
    color: ${({ theme }) => theme.itamar.palette.action.active};
  }
  &:focus-within {
    .form-label {
      color: ${({ theme }) => theme.itamar.palette.primary.main};
    }
  }
`;
const StyledSelect = styled(MuiSelect)<{ $disabledCursorNotAllowed?: boolean }>`
  .MuiSelect-select {
  }
  ${({ $disabledCursorNotAllowed }) =>
    $disabledCursorNotAllowed &&
    `
    & .Mui-disabled {
      cursor: not-allowed;
      
    }
  `}
`;
export type SelectProps = Pick<
  MuiSelectProps,
  | 'label'
  | 'children'
  | 'fullWidth'
  | 'placeholder'
  | 'value'
  | 'IconComponent'
  | 'disabled'
  | 'name'
  | 'onChange'
  | 'displayEmpty'
  | 'labelId'
  | 'onOpen'
  | 'onClose'
  | 'onSelect'
  | 'autoFocus'
  | 'renderValue'
  | 'open'
> & {
  menuMaxHeight?: number;
  menuMaxWidth?: number;
  menuMinWidth?: number;
  menuAutoFocus?: boolean;
  withoutBorder?: boolean;
  withoutPadding?: boolean;
  disablePaddingMenu?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  hideSelectIcon?: boolean;
  disabledCursorNotAllowed?: boolean;
  tooltip?: TooltipFields;
  variant?: 'inline' | 'outlined';
  optional?: boolean;
  helpTooltipText?: string;
  renderValue: (value: any) => React.ReactNode;
  hasEmptyValue?: boolean;
  width?: number;
  height?: number | 'auto';
  maxWidth?: number;
  disablePaddingInMenuItems?: boolean;
  paperStyle?: React.CSSProperties;
  dataTestId?: string;
};
/**
 This component should never have spreads of SelectProps or anything else as it is shared everywhere.
 If you need another props.
 Pick it and thing well how it will impact other places/
 Do not use styled select as it will not alert you when mandatory prop is missing and create inconsistent design
 Changes:

 Don't do:
 1. Don't use styled select as it will not alert about missing mandatory props
 Don't use Form control and Input label outside of this component. the component is in charge of rendering those
 * */
export const Select = forwardRef<any, PropsWithChildren<SelectProps>>(
  (
    {
      name,
      children,
      fullWidth = true,
      placeholder,
      label,
      value,
      menuMaxHeight,
      menuMaxWidth,
      menuMinWidth,
      withoutBorder,
      withoutPadding,
      disablePaddingMenu,
      backgroundColor,
      borderColor,
      IconComponent,
      anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
      transformOrigin = { vertical: 'top', horizontal: 'right' },
      hideSelectIcon = false,
      disabled,
      disabledCursorNotAllowed,
      tooltip,
      onChange,
      displayEmpty,
      labelId,
      onOpen,
      onClose,
      onSelect,
      autoFocus,
      renderValue,
      hasEmptyValue,
      variant = 'outlined',
      optional,
      helpTooltipText,
      width,
      maxWidth,
      height,
      disablePaddingInMenuItems = true,
      menuAutoFocus,
      open,
      paperStyle,
      dataTestId,
    }: SelectProps,
    ref,
  ) => {
    const { theme } = useStiggTheme();
    const isInline = variant === 'inline';
    const sx: SxProps<Theme> = { pointerEvents: 'auto', height: height || 40, maxWidth };
    if (width) {
      sx.width = width;
      fullWidth = false;
    }
    const inlineSx = { display: 'inline-flex', marginLeft: 1, height: 23 };
    if (isInline) {
      fullWidth = false;
      merge(sx, inlineSx);
      anchorOrigin = { vertical: 'bottom', horizontal: 'left' };
      transformOrigin = { vertical: 'top', horizontal: 'left' };
      withoutBorder = true;
      withoutPadding = true;
      backgroundColor = 'transparent';
      menuMaxWidth = 1000;
    } else {
      sx.minHeight = 40;
    }

    const resolvedBackgroundColor =
      backgroundColor || theme.isLightTheme
        ? backgroundColor ?? theme.itamar.palette.white
        : theme.itamar.palette.background.darkBackground;

    const inputStyle: SxProps<Theme> = {
      backgroundColor: !disabled ? `${resolvedBackgroundColor} !important` : undefined,
      border: withoutBorder ? 'none !important' : undefined,
    };

    if (borderColor) {
      sx['.MuiOutlinedInput-notchedOutline'] = { borderColor };
      sx['&.Mui-focused .MuiOutlinedInput-notchedOutline'] = { borderColor };
      sx['&:hover .MuiOutlinedInput-notchedOutline'] = { borderColor };
    }
    const menuStyle: { minWidth?: number } = {};
    if (menuMinWidth) {
      menuStyle.minWidth = menuMinWidth;
    }

    return (
      <FormControl
        ref={ref}
        sx={{ display: 'inline-flex' }}
        variant="outlined"
        fullWidth={fullWidth}
        $withoutPadding={withoutPadding}>
        <OutlinedFormFieldLabel
          disabled={disabled}
          label={label}
          optional={optional}
          helpTooltipText={helpTooltipText}
        />
        <InformationTooltip
          id={labelId}
          arrow
          placement={tooltip?.placement || 'right'}
          title={tooltip?.title || ''}
          $maxWidth={tooltip?.maxWidth}
          PopperProps={{
            onClick: (e) => disabled && e.stopPropagation(),
          }}>
          <StyledSelect
            fullWidth={fullWidth}
            autoFocus={autoFocus}
            onSelect={onSelect}
            onOpen={onOpen}
            onClose={onClose}
            open={open}
            onChange={onChange}
            data-testid={dataTestId}
            name={name}
            sx={sx}
            onClick={(e) => disabled && e.stopPropagation()}
            disabled={disabled}
            $disabledCursorNotAllowed={disabledCursorNotAllowed}
            IconComponent={hideSelectIcon ? () => null : IconComponent || ArrowDropDownIcon}
            displayEmpty={!!placeholder && displayEmpty}
            value={value || 'none'}
            renderValue={(value: any) => {
              // HACK: I couldn't find a good way to show placeholder other than that :/
              const realValue = value === 'none' ? null : value;
              if (!hasEmptyValue && isNil(realValue)) {
                return <Text.B2 color="disabled">{placeholder}</Text.B2>;
              }
              return renderValue(realValue);
            }}
            input={
              <Input
                placeholder={placeholder}
                sx={inputStyle}
                $disablePadding={withoutPadding}
                $disableYPadding={disablePaddingMenu}
                $disableBorder={withoutBorder}
              />
            }
            MenuProps={{
              MenuListProps: { disablePadding: disablePaddingInMenuItems },
              anchorOrigin,
              transformOrigin,
              autoFocus: menuAutoFocus,
              PaperProps: {
                style: {
                  maxWidth: menuMaxWidth || '225px',
                  maxHeight: menuMaxHeight,
                  ...menuStyle,
                },
                sx: {
                  marginTop: 1,
                  boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow,
                  backgroundColor:
                    resolvedBackgroundColor !== 'transparent' ? resolvedBackgroundColor : theme.itamar.palette.white,
                  border: (theme) => {
                    const color = borderColor || theme.isLightTheme ? undefined : theme.itamar.border.borderColor;
                    return color ? `1px solid ${color}` : 'none';
                  },
                  ...paperStyle,
                },
              },
            }}>
            {children}
          </StyledSelect>
        </InformationTooltip>
      </FormControl>
    );
  },
);
