const WHITE = '#FFFFFF';
const BLACK = '#000000';

export const COLORS_DARK = {
  white: WHITE,
  black: BLACK,
  iconDefault: '#EFEFEF', // main icon color - double check that?
  expiredColor: '#CCDAFF',
  sidebarText: WHITE,
  stripe: '#6772e5',
  slack: '#4A154B',
  hubspot: '#F57722',
  awsMarketplace: '#F90',
  // text
  'text.primary': '#EFEFEF', // primary text color
  'text.secondary': '#919191',
  'text.tertiary': '#7086C0',
  'text.disabled': '#696969', // used for disabled icons as well?
  // primary
  'primary.main': '#3E8046', // make sure it is aligned?
  'primary.dark': '#303F9F',
  'primary.light': '#7986CB',
  'primary.contrastText': WHITE,
  'primary.outlinedRestingBackground': '#327EEE80',
  'primary.outlinedHoverBackground': '#327EEE14',
  'primary.containedHoverBackground': '#2F59A1',
  // secondary
  'secondary.main': '#EFEFEF', // secondary CTAs (on welcome to stigg screen)
  'secondary.dark': '#617A98',
  'secondary.light': '#8A95BC',
  'secondary.contrastText': WHITE,
  'secondary.outlinedRestingBackground': '#001E6C80',
  'secondary.outlinedHoverBackground': '#001E6C14',
  'secondary.containedHoverBackground': '#4C6198',
  // action
  'action.active': '#EFEFEF',
  'action.hover': '#2c2c2c',
  'action.selected': '#EBEDF3', // selected item in table
  'action.disabled': '#2A2A2A', // disabled state?
  'action.disabledBackground': '#353535', // border for menu bar (pricing defition)
  'action.focus': '#E6E9F0',
  // error
  // updated main/dark/light to fit dark
  'error.main': '#D60F26',
  'error.dark': '#C0091F',
  'error.light': '#FFB6B1',
  'error.contrastText': WHITE,
  'error.outlinedRestingBackground': '#F4433680',
  'error.outlinedHoverBackground': '#F4433614',
  'error.containedHoverBackground': '#9E392D',
  'error.content': '#5A201A',
  'error.background': '#FFEEED',
  // warning
  // updated main/dark/light to fit dark
  'warning.main': '#C05E0C',
  'warning.dark': '#A25214',
  'warning.light': '#FFD8B9',
  'warning.contrastText': WHITE,
  'warning.outlinedRestingBackground': '#ED6C0280',
  'warning.outlinedHoverBackground': '#ED6C0214',
  'warning.containedHoverBackground': '#9B501D',
  'warning.content': '#5F2B01',
  'warning.background': '#FBF0E7',
  // info
  // updated main/dark/light to fit dark theme
  'info.main': '#1F7DCA',
  'info.dark': '#1A5F97',
  'info.light': '#81C6FF',
  'info.contrastText': WHITE,
  'info.outlinedRestingBackground': '#2196F380',
  'info.outlinedHoverBackground': '#2196F314',
  'info.containedHoverBackground': '#3069A5',
  'info.content': '#1A3C5E',
  'info.background': '#EBF4FD',
  // success
  // updated main/dark/light to fit dark theme
  'success.main': '#4CAF50',
  'success.dark': '#0D350D',
  'success.light': '#BDFFC0',
  'success.contrastText': WHITE,
  'success.outlinedRestingBackground': '#4CAF5080',
  'success.outlinedHoverBackground': '#4CAF5014',
  'success.containedHoverBackground': '#487940',
  'success.content': '#284424',
  'success.background': '#EFF6EE',
  // background
  'background.paper': '#1E1E1E',
  'background.default': '#1c1c1c', // background for web app
  'background.lightBackground': '#1D1D1D', // backgrond for start with a sandbox
  'background.lightBackground2': '#292929', // background for edit mode (in plan)
  'background.darkBackground': '#1e1e1e',
  'background.darkBackground2': '#141414', // sidebar background color
  'background.darkBackground3': '#1e1e1e',
  'background.darkBackground4': '#151515',
  'background.darkBackground5': '#1E1E1E', // product page secondary items
  'background.emptyState': '#111211',
  'background.runningFlow':
    'linear-gradient(109.28deg, #003EE2 25.91%, #1559E7 31.21%, #2D77EC 38.44%, #3F8FF1 46.15%, #4DA0F4 54.34%, #54AAF5 63.01%, #57ADF6 74.09%)',
  'background.sandboxEnvironment': 'linear-gradient(90deg, #002F0A 0%, #1A5E2A 100%)',
  'background.faded': '#26432A', // product page primary items
  'background.darkHover': '#111211',
  // border
  'border.primary': '#343C42',
  // other
  'other.outlineBorder': '#343C42', // TODO: consolidate with border.primary
  'other.outlineBorderLight': '#353535',
  'other.backdropOverlay': '#808EB5',
  'other.filledInputBackground': '#00000017',
  'other.standardInputLine': '#94A1C1',
  'other.snackbarBackground': '#001E6C',
  'other.ratingActive': '#FFB400',
  'other.divider': '#E0E4EC',
  'other.focusBorder': '#3E8046', // focus state
  'other.fadedBorder': 'rgba(229, 242, 255, 0.3)',
  'other.containedBackground': 'rgb(240, 245, 253)',
  'other.boldDivider': '#ADB2C1',
  // shadow
  'shadow.outlined': '#343C42',
  'shadow.lightShadow': '0 0 10px #001E6C1f',
  'shadow.popover':
    '0px 4px 5px -2px rgba(0, 30, 108, 0.2), 0px 7px 10px 1px rgba(0, 30, 108, 0.14), 0px 2px 16px 1px rgba(0, 30, 108, 0.12)',
  'shadow.inset': '#7986CB12',
  'shadow.paper': '0px 0px 14px rgba(53, 64, 82, 0.05)',
  // grey
  'grey.10': '#353535', // used as a border?
  'grey.25': '#292929', // background for pricing definition
  'grey.50': '#2A2A2A', // disabled state
  'grey.80': '#3A3D4D',
  'grey.100': '#575858', // used for "drafts"
  'grey.200': '#9A9A9A', // used for sidebar secondary text - should set as a general secondary text
  'grey.300': '#9A9A9A', // used as secondary text color
  'grey.400': '#808EB6',
  'grey.500': '#6678A7',
  'grey.600': '#4C6198',
  'grey.700': '#334B89',
  'grey.800': '#19347B',
  'grey.900': '#19347B',
};
