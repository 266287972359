import compact from 'lodash/compact';
import { useState, useMemo } from 'react';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import {
  CouponFragment,
  CouponType,
  Currency,
  CustomerResponseFragment,
  SubscriptionCouponDataFragment,
} from '@stigg-types/apiTypes';
import {
  Box,
  Field,
  FormRenderProps,
  GridFlex,
  HighlightText,
  InformationTooltip,
  LongText,
  Text,
} from '@stigg-components';
import { AdditionalInputActionParams, AdditionalInputLayout } from '.';
import { SubscriptionFormFields } from '../SubscriptionForm.types';
import { useGetCoupons } from '../../../../../../coupons/queries/useGetCoupons';
import { formatCoupon } from '../../../../../../coupons/components/utils';
import {
  DynamicDataSearchableOptions,
  DynamicSearchDataLoaderOutput,
} from '../../../../../../../components/DynamicDataSearchableSelect';

const SEARCH_COMPONENT_WIDTH = 400;

type SubscriptionDiscountConfigurationProps = {
  customer: CustomerResponseFragment;
  currency: Currency;
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
};

const useCouponOptions = (
  search: string,
  currency: Currency,
  existingSubscriptionCoupon: CouponFragment | SubscriptionCouponDataFragment | undefined,
): DynamicSearchDataLoaderOutput => {
  const { data, isLoading } = useGetCoupons(search);

  const coupons: DynamicDataSearchableOptions[] = useMemo(() => {
    if (!data || isEmpty(data.coupons)) return [];

    // hack to display coupons that exist on subscription only
    const isSubscriptionCouponExist = data.coupons.find((coupon) => coupon.refId === existingSubscriptionCoupon?.refId);
    const fetchedCoupons = compact([
      ...data.coupons,
      ...(isSubscriptionCouponExist ? [] : [existingSubscriptionCoupon]),
    ]);

    return fetchedCoupons.map((coupon) => ({
      id: coupon.refId,
      render: (search?: string, isDisabled?: boolean) => (
        <GridFlex.RowSpaceBetween alignItems="flex-start" gap={5} width={SEARCH_COMPONENT_WIDTH}>
          <GridFlex.Column>
            <LongText variant="body2">
              <HighlightText text={formatCoupon(coupon)} highlight={search} />
            </LongText>
            <Text.Sub2>
              <HighlightText text={coupon.refId} highlight={search} />
            </Text.Sub2>
          </GridFlex.Column>
          {isDisabled && (
            <Box>
              <Text.Sub2 noWrap>{t('subscriptionForm.discount.invalidCurrency')}</Text.Sub2>
            </Box>
          )}
        </GridFlex.RowSpaceBetween>
      ),
      renderSelected: () => formatCoupon(coupon),
      isInSearch: (search: string) =>
        coupon.name.toLowerCase().includes(search.toLowerCase()) ||
        coupon.refId.toLowerCase().includes(search.toLowerCase()),
      isDisabled: coupon.type === CouponType.Fixed && currency !== Currency.Usd,
      onOptionSelected: ({ setFieldValue }) => {
        setFieldValue('couponRefId', coupon.refId);
        setFieldValue('coupon', coupon);
      },
    }));
  }, [currency, data, existingSubscriptionCoupon]);

  return { isLoading, data: coupons, totalItems: data?.totalCount };
};

export function SubscriptionDiscountConfiguration({
  customer,
  currency,
  formRenderProps,
}: SubscriptionDiscountConfigurationProps) {
  const {
    values: { couponRefId, coupon: existingSubscriptionCoupon },
  } = formRenderProps;
  const [showSearch, setShowSearch] = useState(!!couponRefId);

  const discountFields: Field<SubscriptionFormFields>[] = [
    {
      type: 'custom',
      hide: () => !customer.coupon,
      render: () => (
        <InformationTooltip
          arrow
          placement="top"
          title={<Text.B2>{t('subscriptionForm.discount.customerCouponTooltip')}</Text.B2>}>
          <Box>
            <Text.B2>{formatCoupon(customer.coupon!)}</Text.B2>
          </Box>
        </InformationTooltip>
      ),
    },
    {
      id: 'couponRefId',
      hide: () => !!customer.coupon,
      type: 'dynamicDataSearchableSelect',
      gridProps: {
        minWidth: SEARCH_COMPONENT_WIDTH,
      },
      placeholder: t('subscriptionForm.discount.couponPlaceholder'),
      useDataLoader: (search) => useCouponOptions(search, currency, existingSubscriptionCoupon),
      dataTestId: 'select-coupon',
    },
  ];

  const onSetCoupon = () => {
    setShowSearch(true);
  };

  const onUnsetCoupon = ({ setFieldValue, setFieldTouched }: AdditionalInputActionParams) => {
    setShowSearch(false);
    setFieldValue('coupon', undefined, true);
    setFieldValue('couponRefId', undefined, true);
    setTimeout(() => setFieldTouched('couponRefId', false, true));
  };

  return (
    <GridFlex.Column gap={2}>
      <Text.H6>{t('subscriptionForm.discount.title')}</Text.H6>
      <AdditionalInputLayout
        isSet={!!customer.coupon || showSearch}
        setIcon="Discount"
        setLabel={t('subscriptionForm.discount.coupon')}
        buttonLabel={t('sharedComponents.add')}
        unsetIcon="DiscountDisabled"
        fields={discountFields}
        onSet={onSetCoupon}
        onUnset={!customer.coupon ? onUnsetCoupon : undefined}
        formRenderProps={formRenderProps}
      />
    </GridFlex.Column>
  );
}
