import React from 'react';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components/macro';
import uppercase from 'lodash/upperCase';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ListItemIcon,
  MenuItem as BaseMenuItem,
  MenuDropdown,
  Box,
  Icon,
  Text,
  Avatar,
  GridFlex,
  LongText,
} from '@stigg-components';
import { t } from 'i18next';
import { SidebarItemsType } from '@stigg-types/sidebar';
import SidebarNav from './SidebarNav';
import { appRoutes, useNavigation } from '../../../modules/navigation/useNavigation';
import { RootState } from '../../../redux/store';
import { useLogout } from '../../../modules/auth/hooks/useLogout';

const SidebarFooterWrapper = styled(GridFlex.Column)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-top: 1px solid rgba(117, 134, 176, 0.5);
`;

const MenuItem = styled(BaseMenuItem)`
  &:hover {
    background-color: ${({ theme }) => theme.itamar.palette.environmentSwitch.hoverBackground} !important;
  }
`;

export function SidebarFooter({ isSidebarOpen }: { isSidebarOpen: boolean }) {
  const { user: auth0User } = useAuth0();
  const logout = useLogout();
  const theme = useTheme();
  const navigation = useNavigation();

  const user = useSelector((state: RootState) => state.authReducer.user);

  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);

  const name = user?.name ?? auth0User?.name;
  const email = user?.email ?? auth0User?.email;
  const picture = user?.profileImageUrl ?? auth0User?.picture;
  const iconColor = theme.isLightTheme ? 'active' : 'primary.main';

  return (
    <SidebarFooterWrapper>
      <SidebarNav
        isSidebarOpen={isSidebarOpen}
        items={[
          {
            pages: [
              {
                externalLink: true,
                href: 'https://changelog.stigg.io',
                title: t('sidenav.whatsNew'),
                icon: () => <Icon type="reactFeather" icon="MessageSquare" color={iconColor} overrideStroke />,
              },
            ] as SidebarItemsType[],
          },
          {
            pages: [
              {
                href: '/account/settings',
                title: t('sidenav.settings'),
                dataTestId: 'sidenav-setting',
                icon: () => <Icon type="reactFeather" icon="Sliders" color={iconColor} overrideStroke />,
              },
            ] as SidebarItemsType[],
          },
        ]}
      />

      <GridFlex.Column sx={{ padding: '0 16px 16px 16px' }}>
        <MenuDropdown
          isMenuWidthBasedOnTriggerWidth
          backgroundColor={(theme) =>
            theme.isLightTheme
              ? theme.itamar.palette.background.fadedBackground
              : theme.itamar.palette.environmentSwitch.background
          }
          borderColor={theme.itamar.palette.environmentSwitch.border}
          menuMarginTop={isSidebarOpen ? '-8px' : '8px'}
          menuMarginLeft={isSidebarOpen ? '0' : '20px'}
          anchorOrigin={
            isSidebarOpen ? { horizontal: 'left', vertical: 'top' } : { horizontal: 'right', vertical: 'bottom' }
          }
          transformOrigin={
            isSidebarOpen ? { horizontal: 'right', vertical: 'bottom' } : { horizontal: 'left', vertical: 'bottom' }
          }
          dropdownTrigger={
            isSidebarOpen ? (
              <GridFlex.RowCenter
                sx={{
                  backgroundColor: (theme) =>
                    theme.isLightTheme
                      ? theme.itamar.palette.background.fadedBackground
                      : theme.itamar.palette.environmentSwitch.background,
                  borderRadius: '10px',
                  padding: '8px',
                  cursor: 'pointer',
                }}>
                <Avatar sx={{ width: '36px', height: '36px' }} alt="user-avatar" src={picture}>
                  {uppercase(name?.slice(0, 2))}
                </Avatar>
                <GridFlex.Column ml={4}>
                  <LongText color="white" wordBreak>
                    {name || ''}
                  </LongText>
                  <LongText color="disabled" wordBreak>
                    {email || ''}
                  </LongText>
                </GridFlex.Column>
                <Box sx={{ marginLeft: 'auto', mt: 1 }}>
                  <Icon type="custom" icon="ChevronsUpDown" color={iconColor} size={16} overrideStroke />
                </Box>
              </GridFlex.RowCenter>
            ) : (
              <Avatar sx={{ cursor: 'pointer', width: '36px', height: '36px' }} alt="user-avatar" src={picture}>
                {uppercase(user?.name?.slice(0, 2))}
              </Avatar>
            )
          }
          menuItems={
            <GridFlex.Column>
              {!currentEnvironment?.isSandbox && (
                <MenuItem onClick={() => navigation.navigateTo('/getting-started')}>
                  <GridFlex.RowCenter py={1}>
                    <ListItemIcon>
                      <Icon icon="PlayCircleOutline" type="materialIcons" size={22} color={iconColor} overrideStroke />
                    </ListItemIcon>
                    <Text.B2 color="white">{t('navbar.getStartedWithStigg')}</Text.B2>
                  </GridFlex.RowCenter>
                </MenuItem>
              )}
              <MenuItem onClick={() => navigation.navigateTo(appRoutes.switchAccountPage(), { isGlobal: true })}>
                <GridFlex.RowCenter py={1}>
                  <ListItemIcon>
                    <Icon icon="SwitchAccount" size={24} type="custom" color={iconColor} overrideStroke />
                  </ListItemIcon>
                  <Text.B2 color="white">{t('switchAccount')}</Text.B2>
                </GridFlex.RowCenter>
              </MenuItem>
              <MenuItem onClick={logout}>
                <GridFlex.RowCenter py={1}>
                  <ListItemIcon>
                    <Icon icon="LogOut" size={20} type="reactFeather" color={iconColor} overrideStroke />
                  </ListItemIcon>
                  <Text.B2 color="white">{t('signOut')}</Text.B2>
                </GridFlex.RowCenter>
              </MenuItem>
            </GridFlex.Column>
          }
        />
      </GridFlex.Column>
    </SidebarFooterWrapper>
  );
}
