const WHITE = '#FFFFFF';
const BLACK = '#000000';

export const COLORS = {
  white: WHITE,
  black: BLACK,
  iconDefault: '#EFEFEF', // main icon color - double check that?
  expiredColor: '#CCDAFF',
  sidebarText: WHITE,
  stripe: '#6772e5',
  slack: '#4A154B',
  hubspot: '#F57722',
  awsMarketplace: '#F90',
  // text
  'text.primary': '#001E6C',
  'text.secondary': '#001E6CBF',
  'text.tertiary': '#7086C0',
  'text.disabled': '#99A5C4',
  // primary
  'primary.main': '#327EEE',
  'primary.dark': '#303F9F',
  'primary.light': '#7986CB',
  'primary.contrastText': WHITE,
  'primary.outlinedRestingBackground': '#327EEE80',
  'primary.outlinedHoverBackground': '#327EEE14',
  'primary.containedHoverBackground': '#2F59A1',
  // secondary
  'secondary.main': '#6678A7',
  'secondary.dark': '#617A98',
  'secondary.light': '#8A95BC',
  'secondary.contrastText': WHITE,
  'secondary.outlinedRestingBackground': '#001E6C80',
  'secondary.outlinedHoverBackground': '#001E6C14',
  'secondary.containedHoverBackground': '#4C6198',
  // action
  'action.active': '#7586B0',
  'action.hover': '#F5F6F9',
  'action.selected': '#EBEDF3',
  'action.disabled': '#F2F4F8',
  'action.disabledBackground': '#D9DDE9',
  'action.focus': '#E6E9F0',
  // error
  'error.main': '#D94052',
  'error.dark': '#B63745',
  'error.light': '#F88078',
  'error.contrastText': WHITE,
  'error.outlinedRestingBackground': '#F4433680',
  'error.outlinedHoverBackground': '#F4433614',
  'error.containedHoverBackground': '#9E392D',
  'error.content': '#5A201A',
  'error.background': '#FFEEED',
  // warning
  'warning.main': '#ED6C02',
  'warning.dark': '#C77700',
  'warning.light': '#FFB547',
  'warning.contrastText': WHITE,
  'warning.outlinedRestingBackground': '#ED6C0280',
  'warning.outlinedHoverBackground': '#ED6C0214',
  'warning.containedHoverBackground': '#9B501D',
  'warning.content': '#5F2B01',
  'warning.background': '#FBF0E7',
  // info
  'info.main': '#2196F3',
  'info.dark': '#0B79D0',
  'info.light': '#64B6F7',
  'info.contrastText': WHITE,
  'info.outlinedRestingBackground': '#2196F380',
  'info.outlinedHoverBackground': '#2196F314',
  'info.containedHoverBackground': '#3069A5',
  'info.content': '#1A3C5E',
  'info.background': '#EBF4FD',
  // success
  'success.main': '#4CAF50',
  'success.dark': '#3B873E',
  'success.light': '#7BC67E',
  'success.contrastText': WHITE,
  'success.outlinedRestingBackground': '#4CAF5080',
  'success.outlinedHoverBackground': '#4CAF5014',
  'success.containedHoverBackground': '#487940',
  'success.content': '#284424',
  'success.background': '#EFF6EE',
  // background
  'background.paper': WHITE,
  'background.default': '#F5F6F9',
  'background.lightBackground': '#F8FCFE',
  'background.lightBackground2': '#E5F2FF',
  'background.darkBackground': '#001E6C',
  'background.darkBackground2': '#000F36',
  // TODO: REMOVE THESE
  'background.darkBackground3': '#F5F6F9',
  'background.darkBackground4': '#F5F6F9',
  'background.darkBackground5': '#F5F6F9', // product page secondary items
  'background.emptyState': '#F5F6F9',
  'background.runningFlow':
    'linear-gradient(109.28deg, #003EE2 25.91%, #1559E7 31.21%, #2D77EC 38.44%, #3F8FF1 46.15%, #4DA0F4 54.34%, #54AAF5 63.01%, #57ADF6 74.09%)',
  'background.sandboxEnvironment': 'linear-gradient(90.65deg, #6693F4FF 0%, #9FD295FF 100%)',
  'background.faded': '#2E3C5E',
  'background.darkHover': '#27324f',
  // border
  'border.primary': '#343C42',
  // other
  'other.outlineBorder': '#C4CBDD',
  'other.outlineBorderLight': '#D9DDE9',
  'other.backdropOverlay': '#808EB5',
  'other.filledInputBackground': '#00000017',
  'other.standardInputLine': '#94A1C1',
  'other.snackbarBackground': '#001E6C',
  'other.ratingActive': '#FFB400',
  'other.divider': '#E0E4EC',
  'other.focusBorder': '#1559E6',
  'other.fadedBorder': 'rgba(229, 242, 255, 0.3)',
  'other.containedBackground': 'rgb(240, 245, 253)',
  'other.boldDivider': '#ADB2C1',
  // shadow
  'shadow.outlined': '#001E6C26',
  'shadow.lightShadow': '0 0 10px #001E6C1f',
  'shadow.popover':
    '0px 4px 5px -2px rgba(0, 30, 108, 0.2), 0px 7px 10px 1px rgba(0, 30, 108, 0.14), 0px 2px 16px 1px rgba(0, 30, 108, 0.12)',
  'shadow.inset': '#7986CB12',
  'shadow.paper': '0px 0px 14px rgba(53, 64, 82, 0.05)',
  // grey
  'grey.10': '#EBECF2',
  'grey.25': '#F8F9FB',
  'grey.50': '#F2F4F8',
  'grey.80': '#3A3D4D',
  'grey.100': '#E5E8F0',
  'grey.200': '#B2BBD3',
  'grey.300': '#99A5C4',
  'grey.400': '#808EB6',
  'grey.500': '#6678A7',
  'grey.600': '#4C6198',
  'grey.700': '#334B89',
  'grey.800': '#19347B',
  'grey.900': '#19347B',
};
