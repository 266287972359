import { t } from 'i18next';
import { BillingPeriod } from '@stigg-types/apiTypes';
import { Text, FormRenderProps, GridFlex, OutlinedFormFieldLabel, SelectableButton } from '@stigg-components';
import { PlanPricePoints, SubscriptionFormFields } from '../SubscriptionForm.types';
import { switchToCreateModeTooltip } from '.';

export type BillingCycleProps = {
  planPricePoints: PlanPricePoints;
  isUpdatingSubscription?: boolean;
  billingPeriod?: BillingPeriod;
  formProps: FormRenderProps<SubscriptionFormFields>;
  switchToCreateMode: () => void;
};

export function BillingCycle({
  planPricePoints,
  isUpdatingSubscription,
  billingPeriod,
  formProps: { setFieldValue },
  switchToCreateMode,
}: BillingCycleProps) {
  const hasMonthly = !!planPricePoints[BillingPeriod.Monthly];
  const hasAnnually = !!planPricePoints[BillingPeriod.Annually];
  const disableTooltip = isUpdatingSubscription
    ? switchToCreateModeTooltip(t('subscriptionForm.switchToCreateModeForBillingCycle'), switchToCreateMode)
    : undefined;

  return (
    <>
      <OutlinedFormFieldLabel label={t('subscriptionForm.billingPeriod')} />
      <GridFlex.Row>
        {hasMonthly && (
          <SelectableButton
            disabled={isUpdatingSubscription}
            buttonGroupPosition={hasAnnually ? 'left' : undefined}
            isSelected={BillingPeriod.Monthly === billingPeriod}
            dataTestId={`button-billing-period-${BillingPeriod.Monthly}`}
            onClick={() => setFieldValue('billingPeriod', BillingPeriod.Monthly)}
            tooltip={disableTooltip}>
            <Text.B2>{t(`pricing.billingPeriodDescriptions.${BillingPeriod.Monthly}`)}</Text.B2>
          </SelectableButton>
        )}
        {hasAnnually && (
          <SelectableButton
            disabled={isUpdatingSubscription}
            buttonGroupPosition={hasMonthly ? 'right' : undefined}
            isSelected={BillingPeriod.Annually === billingPeriod}
            dataTestId={`button-billing-period-${BillingPeriod.Annually}`}
            onClick={() => setFieldValue('billingPeriod', BillingPeriod.Annually)}
            tooltip={disableTooltip}>
            <Text.B2>{t(`pricing.billingPeriodDescriptions.${BillingPeriod.Annually}`)}</Text.B2>
          </SelectableButton>
        )}
      </GridFlex.Row>
    </>
  );
}
