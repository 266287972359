import styled from 'styled-components/macro';
import { Button, GridFlex } from '@stigg-components';

const TRANSITION = '0.3s ease-in-out';

export const Container = styled(GridFlex.Column)`
  background: ${({ theme }) => theme.itamar.palette.background.paper};
  width: 100vw;
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const SandboxOptionPriceSection = styled.div<{ color: string }>`
  flex: 1;
  background: ${({ color }) => `linear-gradient(40deg, ${color} 0%, #2B8C33 130.94%)`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const SandboxOptionDescriptionSection = styled.div`
  background: ${({ theme }) => theme.itamar.palette.background.paper};
  height: 190px;
  transition: height ${TRANSITION};
  position: relative;
`;

export const SandboxOptionDescriptionPriceSection = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  width: 274px;
  height: 120px;
  border: none;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SandboxOptionDescriptionPriceSubtitleWrapper = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
`;

export const UnderlineSketch = styled.div<{ color: string }>`
  margin-top: 2px;
  height: 1.6px;
  background: ${({ color }) => color};
`;

export const SandboxOptionDescriptionLogosWrapper = styled.div`
  opacity: 1;
  transition: opacity ${TRANSITION};
`;

export const SandboxOptionDescriptionExtraWrapper = styled.div`
  opacity: 0;
  position: absolute;
  top: 86px;
  left: 0;
  width: 100%;
  padding: 0 24px 0 24px;
  transition: opacity ${TRANSITION};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const SandboxOptionWrapper = styled.div`
  cursor: pointer;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorderLight};
  width: 342px;
  height: 378px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;

  &:hover {
    ${SandboxOptionDescriptionSection} {
      height: 225px;
    }

    ${SandboxOptionDescriptionLogosWrapper} {
      opacity: 0;
    }

    ${SandboxOptionDescriptionExtraWrapper} {
      opacity: 1;
    }
  }
`;

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.itamar.palette.primary.main};
`;
