import { getStiggTheme } from '@stigg-theme';
import typography from '../../../../theme/typography';

export const OTHERS_COLOR = getStiggTheme().theme.itamar.palette.grey[100];
export const OTHERS_COLOR_LINE = getStiggTheme().theme.itamar.palette.grey[200];
export const WHITE = getStiggTheme().theme.itamar.palette.white;

export const opacityColor = (color: string, opacity = 1) => {
  const hex = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0');
  return `${color}${hex}`;
};

export const LEGEND_BOX_SIZE = 12;

export const LABEL_COLOR = getStiggTheme().theme.itamar.palette.text.secondary;
export const LABEL_FONT = {
  family: typography.body2?.fontFamily as string,
  size: typography.body2?.fontSize as number,
};

export const AXIS_TITLE_COLOR = getStiggTheme().theme.itamar.palette.text.primary;
export const AXIS_TITLE_FONT = {
  family: typography.body2?.fontFamily as string,
  size: typography.body2?.fontSize as number,
};

export const AXIS_TICK_COLOR = getStiggTheme().theme.itamar.palette.text.secondary;
export const AXIS_TICK_FONT = {
  family: typography.body2?.fontFamily as string,
  size: typography.body2?.fontSize as number,
};

export const GRID_BORDER_DASH = [8, 8];
export const AXIS_TITLE_SPACING = 15;
