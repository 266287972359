import { useState } from 'react';
import {
  CustomerResponseFragment,
  CustomerSubscriptionDataFragment,
  PlanFragment,
  PlanListFragment,
} from '@stigg-types/apiTypes';
import delay from 'lodash/delay';
import { Dialog } from '../../../../../../components/Dialog';
import { useAppDispatch } from '../../../../../../redux/store';
import { fetchCustomerSubscriptionsAction, fetchEntitlementsByRefIdAction } from '../../../../customersSlice';
import { SubscriptionForm } from '../subscriptionForm/SubscriptionForm';
import { SubscriptionFormFields } from '../subscriptionForm/SubscriptionForm.types';
import * as subscriptionActions from '../../../subscriptions/subscriptionActions';

type UseCreateSubscriptionProps = {
  customer: CustomerResponseFragment;
  pageSize?: number;
};

export const useSubscriptionActions = ({ customer, pageSize }: UseCreateSubscriptionProps) => {
  const dispatch = useAppDispatch();
  const [createSubscriptionOpen, setCreateSubscriptionOpen] = useState(false);
  const [subscriptionToEdit, setSubscriptionToEdit] = useState<CustomerSubscriptionDataFragment | null | undefined>();

  const switchToCreateMode = () => {
    setCreateSubscriptionOpen(false);
    setSubscriptionToEdit(null);
    delay(() => {
      setCreateSubscriptionOpen(true);
    }, 300);
  };

  const onCreateSubscriptionClick = () => {
    setSubscriptionToEdit(null);
    setCreateSubscriptionOpen(true);
  };

  const onUpdateSubscriptionClick = (subscription: CustomerSubscriptionDataFragment) => {
    setSubscriptionToEdit(subscription);
    setCreateSubscriptionOpen(true);
  };

  const closeCreateSubscriptionDialog = () => {
    setSubscriptionToEdit(null);
    setCreateSubscriptionOpen(false);
  };

  const handleCreateSubscription = async (
    values: SubscriptionFormFields,
    plan?: PlanListFragment | PlanFragment,
    isAllowEmptyResourceIdEnabled?: boolean,
  ) => {
    await subscriptionActions.handleCreateSubscription(
      customer.customerId,
      dispatch,
      values,
      plan,
      isAllowEmptyResourceIdEnabled,
    );
  };

  const handleUpdateSubscription = async (values: SubscriptionFormFields) => {
    if (!subscriptionToEdit?.subscriptionId) {
      return;
    }

    await subscriptionActions.handleUpdateSubscription(subscriptionToEdit.subscriptionId, values, dispatch);

    await dispatch(
      fetchCustomerSubscriptionsAction({
        customerId: customer.customerId,
        isSilentLoading: true,
        paging: { first: pageSize },
      }),
    );
    await dispatch(
      fetchEntitlementsByRefIdAction({
        customerRefId: customer.customerId,
        resourceRefId: undefined,
      }),
    );
  };

  const createSubscriptionDialog = (
    <Dialog
      disableCloseOnEscapeKey
      fullScreen
      padding={0}
      content={
        <SubscriptionForm
          customer={customer}
          onCancel={closeCreateSubscriptionDialog}
          handleSubmit={subscriptionToEdit ? handleUpdateSubscription : handleCreateSubscription}
          subscription={subscriptionToEdit}
          switchToCreateMode={switchToCreateMode}
        />
      }
      isOpen={createSubscriptionOpen}
      onCancel={closeCreateSubscriptionDialog}
      aria-labelledby="add-customer-subscription"
    />
  );

  return {
    createSubscriptionDialog,
    onCreateSubscriptionClick,
    onUpdateSubscriptionClick,
    handleCreateSubscription,
    handleUpdateSubscription,
  };
};
