import { useStiggTheme, THEMES } from '@stigg-theme';
import { useHover } from '@stigg-common';
import { Around } from '@theme-toggles/react';

import '@theme-toggles/react/css/Around.css';

export function ThemeToggle() {
  const [themeSwitchHoverRef, isThemeSwitchHover] = useHover();
  const { theme, changeThemeVariant } = useStiggTheme();

  const themeToSwitchTo = theme.isLightTheme ? THEMES.DARK : THEMES.LIGHT;

  return (
    <Around
      ref={themeSwitchHoverRef}
      color={theme.itamar.palette.primary.main}
      duration={750}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 24,
        color: theme.itamar.palette.primary.main,
        transition: 'border 0.3s ease-in-out',
        border: `1px solid ${
          isThemeSwitchHover ? theme.itamar.palette.primary.main : theme.itamar.palette.other.outlineBorderLight
        }`,
        borderRadius: theme.itamar.border.radius,
        width: 40,
      }}
      toggled={!theme.isLightTheme}
      toggle={() => changeThemeVariant(themeToSwitchTo)}
    />
  );
}
