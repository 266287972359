import { map, startCase } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Checkbox, ListSubheader, Tooltip, Box, Select, Chip, Grid, GridFlex, Text } from '@stigg-components';
import React from 'react';
import { t } from 'i18next';
import { EventLogType } from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { findIndexOfEventTypeToRemove, formatEventLogTypeName } from '../../hookUtils';
import {
  EVENT_LOG_TYPES_BY_GROUP,
  SLACK_LOG_TYPES_BY_GROUP,
  SlackWebhookEventLogType,
  SUPPORTED_EVENT_LOG_TYPES,
} from '../../constants';

export default function EventsSelector({
  selectedEventLogTypes,
  push,
  remove,
  setFieldValue,
  isSlackIntegrationHook,
}: {
  selectedEventLogTypes: EventLogType[];
  push: (param: any) => void;
  remove: (param: any) => void;
  setFieldValue: (field: string, value: any) => void;
  isSlackIntegrationHook?: boolean;
}) {
  const { spentLimitExceededWebhooksThresholdsUi: isSpendingLimitExceededHookEnabled } = useFlags<FeatureFlags>();
  const allSelected =
    selectedEventLogTypes.length ===
    Object.keys(isSlackIntegrationHook ? SlackWebhookEventLogType : SUPPORTED_EVENT_LOG_TYPES).length;
  const events = isSpendingLimitExceededHookEnabled
    ? SUPPORTED_EVENT_LOG_TYPES
    : SUPPORTED_EVENT_LOG_TYPES.filter((x) => x !== EventLogType.SubscriptionSpentLimitExceeded);
  return (
    <Select
      height="auto"
      fullWidth
      menuMaxHeight={350}
      labelId="hook-type"
      value={selectedEventLogTypes.length ? selectedEventLogTypes : ''}
      renderValue={(selected: any) => {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, overflow: 'auto', maxHeight: 80 }}>
            {selected.map((eventLogType: any) => (
              <Tooltip arrow placement="top" title={t(`hooks.eventsDescriptions.${eventLogType}`) || ''}>
                <Chip
                  variant="outlined"
                  textColor="primary"
                  label={formatEventLogTypeName(eventLogType)}
                  key={eventLogType}
                  onMouseDown={(e) => e.stopPropagation()}
                  onDelete={(e) => {
                    e.stopPropagation();
                    remove(findIndexOfEventTypeToRemove(selectedEventLogTypes, eventLogType));
                  }}
                />
              </Tooltip>
            ))}
          </Box>
        );
      }}>
      <GridFlex.Column container>
        <Grid item pl={4} key="eventLogtypeselectall">
          <Checkbox
            label={<Text.B2 mt={0.5}>{t('hooks.selectAll')}</Text.B2>}
            // prevent selector to close after each checkbox pressed
            onClick={(e) => e.stopPropagation()}
            checked={allSelected}
            onChange={(e) =>
              setFieldValue(
                'eventLogTypes',
                e.target.checked ? Object.values(isSlackIntegrationHook ? SlackWebhookEventLogType : events) : [],
              )
            }
          />
        </Grid>
        {map(
          isSlackIntegrationHook ? SLACK_LOG_TYPES_BY_GROUP : EVENT_LOG_TYPES_BY_GROUP,
          (eventLogGroupTypes, groupName) => {
            const filteredEventLogGroupTypes = isSpendingLimitExceededHookEnabled
              ? eventLogGroupTypes
              : eventLogGroupTypes.filter(
                  (eventLogType) => eventLogType !== EventLogType.SubscriptionSpentLimitExceeded,
                );

            return (
              <GridFlex.Column container key={groupName}>
                <ListSubheader sx={{ px: 4, py: 3 }}>
                  <Text.Sub2>{startCase(groupName)}</Text.Sub2>
                </ListSubheader>
                {map(filteredEventLogGroupTypes, (eventLogType: EventLogType) => (
                  <Grid item pl={4} key={`eventLogtype${eventLogType}`}>
                    <Tooltip arrow placement="right" title={t(`hooks.eventsDescriptions.${eventLogType}`) || ''}>
                      <Checkbox
                        // prevent selector to close after each checkbox pressed
                        onClick={(e) => e.stopPropagation()}
                        checked={findIndexOfEventTypeToRemove(selectedEventLogTypes, eventLogType) > -1}
                        key={eventLogType}
                        value={eventLogType}
                        onChange={(e) => {
                          if (e.target.checked) {
                            push(eventLogType);
                          } else {
                            remove(findIndexOfEventTypeToRemove(selectedEventLogTypes, eventLogType));
                          }
                        }}
                        label={formatEventLogTypeName(eventLogType)}
                      />
                    </Tooltip>
                  </Grid>
                ))}
              </GridFlex.Column>
            );
          },
        )}
      </GridFlex.Column>
    </Select>
  );
}
