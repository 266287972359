import { styled as muiStyled, Theme, CSSObject } from '@mui/material/styles';
import styled, { css } from 'styled-components/macro';
import { IconButton, ListItemButton, Drawer as MuiDrawer } from '@stigg-components';
import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED } from '../AppLayout.style';
import { StiggLogo } from '../../StiggLogo';

const BRAND_TOP_PADDING = 20;
const BRAND_LEFT_PADDING = 17;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: DRAWER_WIDTH_CLOSED,
  [theme.breakpoints.up('sm')]: {
    width: DRAWER_WIDTH_CLOSED,
  },
});

export const Drawer = muiStyled(MuiDrawer)<{ $openDelayed: boolean }>(({ theme, open, $openDelayed }) => ({
  width: DRAWER_WIDTH,
  ...(open && {
    ...openedMixin(theme),
    '& > .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& > .MuiDrawer-paper': closedMixin(theme),
  }),
  ...(!$openDelayed && {
    '.chevron-icon-button': {
      opacity: 0,
    },
    '.brand-icon': {
      opacity: 1,
    },
    '&:hover': {
      '.chevron-icon-button': {
        opacity: 1,
      },
      '.brand-icon': {
        opacity: 0,
      },
    },
  }),
}));

export const Brand = styled(ListItemButton)<{ component?: React.ReactNode; to?: string }>`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.itamar.palette.grey[300]};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  cursor: pointer;
  flex-grow: 0;
  justify-content: space-between;
  padding: ${BRAND_TOP_PADDING}px 17px 25px ${BRAND_LEFT_PADDING}px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

export const BrandIcon = styled(StiggLogo)<{ $floating?: boolean; $sidebar?: boolean; $open: boolean }>`
  width: 97px;
  height: 32px;
  flex-shrink: 0;
  position: ${({ $floating }) => ($floating ? 'absolute' : 'unset')};
  top: ${({ $floating }) => ($floating ? `${BRAND_TOP_PADDING}px` : 'unset')};
  left: ${({ $floating }) => ($floating ? `${BRAND_LEFT_PADDING}px` : 'unset')};
  color: ${({ theme, $sidebar }) => ($sidebar ? theme.sidebar.header.brand.color : theme.itamar.palette.text.primary)};

  path {
    transition: opacity 0.15s ease-in-out;
  }
  ${({ $open }) =>
    !$open &&
    css`
      path.stigg-text {
        opacity: 0;
      }
    `}
`;

export const IconButtonFaded = styled(IconButton)`
  &:hover {
    background-color: ${({ theme }) => theme.itamar.palette.background.fadedBackground};
  }
`;
