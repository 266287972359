import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AccountStatus, User } from '@stigg-types/apiTypes';
import merge from 'lodash/merge';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { RootState } from '../../redux/store';
import { AnalyticsContext } from './AnalyticsProvider';
import { useCurrentMember } from '../auth/hooks/useCurrentMember';

type Analytics = {
  track: (eventName: SegmentEvents | string, obj?: any) => void;
  identify: (username: string, obj?: any) => void;
  alias: (username: string) => void;
  page: () => void;
  group: (groupId: string, obj?: any) => void;
};

export enum SegmentEvents {
  ButtonClicked = 'Button Clicked',
}

const useAnalyticsContext = () => {
  const analytics = useContext(AnalyticsContext);
  if (!analytics) {
    throw new Error(
      'Analytics context was not initialized, AnalyticsProvider is probably missing from the component tree',
    );
  }

  return analytics;
};

export function useAnalytics(): Analytics {
  const analytics = useAnalyticsContext();
  const user: User | null = useSelector((state: RootState) => state.authReducer.user);
  const { setAccountToBlockedOnCreation: accountShouldBeBlocked } = useFlags<FeatureFlags>();
  const member = useCurrentMember();

  // useEffect(() => {
  //   if (!window.analytics && config.segmentKey) {
  //     const e = document.createElement('script');
  //     e.type = 'text/javascript';
  //     e.async = !0;
  //     e.src = `${document.location.protocol === 'https:' ? 'https://' : 'http://'}cdn.segment.com/analytics.js/v1/${
  //       config.segmentKey
  //     }/analytics.min.js`;
  //     const n = document.getElementsByTagName('script')[0];
  //     // @ts-ignore
  //     n.parentNode.insertBefore(e, n);
  //   }
  // }, []);
  return useMemo(() => {
    let identityContext = {};
    let identityContextWithIsBlocked = {};
    if (user) {
      identityContext = {
        email: user.email || '',
        name: user.name || '',
        id: user.id || '',
        ...(member?.account
          ? {
              account: member.account.displayName || '',
              accountId: member.account.id || '',
            }
          : {}),
      };

      identityContextWithIsBlocked = {
        ...identityContext,
        ...(member?.account
          ? { isBlocked: member.account.accountStatus === AccountStatus.Blocked || accountShouldBeBlocked }
          : {}),
      };
    }
    const track = (eventName: SegmentEvents | string, obj?: any) => {
      if (analytics) {
        void analytics.track(eventName, merge(obj, identityContextWithIsBlocked));
      }
    };
    const identify = (username: string, obj?: any) => {
      if (analytics) {
        void analytics.identify(username, merge(obj, identityContext));
      }
    };

    const alias = (username: string) => {
      if (analytics) {
        void analytics.alias(username);
        // analytics.flush();
      }
    };

    const page = () => {
      if (analytics) {
        void analytics.page({ properties: identityContextWithIsBlocked });
      }
    };
    // this is used only to group members on same accounts for hubspot
    const group = (accountId: string, obj?: any) => {
      if (analytics) {
        void analytics.group(accountId, merge(obj, identityContextWithIsBlocked));
      }
    };
    return {
      track,
      identify,
      page,
      group,
      alias,
    };
  }, [user, member, accountShouldBeBlocked, analytics]);
}
