import styled, { css } from 'styled-components/macro';
import { ReactComponent as FadedGridSVG } from '@assets/images/fadedGrid.svg';
import { Card } from '@stigg-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.itamar.palette.background.lightBackground};
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const FadedGrid = styled(FadedGridSVG)`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const FormWrapper = styled.div`
  max-width: 600px;
  background: ${({ theme }) => theme.itamar.palette.white};
  box-shadow: ${({ theme }) => theme.itamar.palette.shadow.paper};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  padding: 24px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const CardWrapper = styled(Card)`
  padding: 16px;
  min-height: 32px;
  cursor: auto;
  ${({ theme }) =>
    css`
      cursor: pointer;
      &:hover {
        box-shadow: ${theme.itamar.palette.shadow.lightShadow};
        border-color: ${({ theme }) => theme.itamar.palette.primary.main};
      }
    `}
`;
