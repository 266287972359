import { useState } from 'react';
import { t } from 'i18next';
import { isEmpty, delay } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import {
  CustomerSubscriptionDataFragment,
  PlanFragment,
  PlanListFragment,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { GridFlex } from '@stigg-components';
import { useBreadcrumbs } from '@stigg-common';
import { ActiveFutureUpdateStatus } from '@stigg-common/types';
import Loader from '../../../../components/Loader';
import { useAppDispatch } from '../../../../redux/store';
import {
  fetchSubscriptionByRefIdAction,
  triggerSubscriptionUsageSyncAction,
  updateSubscriptionAction,
} from '../../customersSlice';
import { SubscriptionAddons } from './subscriptionAddons/SubscriptionAddons';
import { CancelSubscriptionForm } from '../customerPage/customerSubscriptions/CancelSubscriptionForm';
import { Dialog } from '../../../../components/Dialog';
import { SubscriptionInfo } from './subscriptionInfo/SubscriptionInfo';
import { EntityMetadataCard } from '../../../../components/entityMetadata/EntityMetadataCard';
import { SubscriptionForm } from '../customerPage/customerSubscriptions/subscriptionForm/SubscriptionForm';
import { SubscriptionFormFields } from '../customerPage/customerSubscriptions/subscriptionForm/SubscriptionForm.types';
import Tabs from '../../../../components/tabs/Tabs';
import { CustomerEntitlementsUsage } from '../customerPage/customerEntitlementsUsage/CustomerEntitlementsUsage';
import { CustomerEntitlementsSummary } from '../customerPage/customerEntitlementSummary/CustomerEntitlementsSummary';
import { findSubscriptionsPlansOfStatus } from '../customerPage/customerEntitlementSummary/components/entitlementSummary.utils';
import { useMigrateSubscriptionDialog, useSubscriptionPage, useSubscriptionPageDialogs } from './hooks';
import EventLog from '../../../eventsLog/components/EventLog';
import SubscriptionHeader from './SubscriptionHeader';
import { SubscriptionPageTabs } from '../../../navigation/tabs';
import { appRoutes, useNavigation } from '../../../navigation/useNavigation';
import { handleCreateSubscription, handleUpdateSubscription } from './subscriptionActions';

export default function SubscriptionPage() {
  const dispatch = useAppDispatch();
  const { eventLogInEntityContext: showEventLog } = useFlags<FeatureFlags>();

  const navigation = useNavigation();
  const {
    refId,
    subscription,
    activeSubscriptions,
    isLoadingSubscription,
    entitlements,
    entitlementsLastUpdated,
    isLoadingEntitlements,
    customer,
    dispatchFetchEntitlements,
  } = useSubscriptionPage();

  const {
    cancelSubscriptionDialogOpen,
    setCancelSubscriptionDialogOpen,
    editSubscriptionDialogOpen,
    setEditSubscriptionDialogOpen,
    setCancelFutureUpdatesStatus,
    CancelScheduledUpdatesDialog,
    CancelScheduledUpdatesDialogProps,
    showCancelScheduledUpdatesDialog,
    MarkInvoiceAsPaidDialog,
    setMarkInvoiceAsPaidDialogOpen,
    markInvoiceAsPaidDialogProps,
  } = useSubscriptionPageDialogs({ subscription });
  const [createSubscriptionMode, setCreateSubscriptionMode] = useState(false);

  const switchToCreateMode = () => {
    setEditSubscriptionDialogOpen(false);
    setCreateSubscriptionMode(true);
    delay(() => {
      setEditSubscriptionDialogOpen(true);
    }, 300);
  };

  const onCloseSubscriptionForm = () => {
    setEditSubscriptionDialogOpen(false);
    setCreateSubscriptionMode(false);
  };

  const { MigrateToLatestDialog, migrateToLatestDialogProps, showMigrateToLatestDialog } = useMigrateSubscriptionDialog(
    { subscription },
  );

  useBreadcrumbs(
    { to: '/customers', title: t('breadCrumbs.customers') },
    subscription?.customer && {
      to: `/customers/${subscription.customer.customerId}`,
      title: subscription.customer.customerId,
    },
    subscription && { title: t('subscriptions.breadcrumbsPrefix', { refId: subscription.subscriptionId }) },
  );

  const onSubmitSetMetaData = async (metadata: Record<string, string>) => {
    await dispatch(
      updateSubscriptionAction({ subscriptionId: subscription.subscriptionId, additionalMetaData: metadata }),
    );
  };

  const onCancelSubscriptionFutureUpdatesClick = (futureUpdateStatus: ActiveFutureUpdateStatus) => {
    setCancelFutureUpdatesStatus(futureUpdateStatus);
    showCancelScheduledUpdatesDialog(true);
  };

  const onTriggerSubscriptionUsageSync = () => {
    void dispatch(
      triggerSubscriptionUsageSyncAction({
        customerId: subscription.customer.customerId,
        resourceId: subscription.resource?.resourceId,
      }),
    );
  };

  const onShowEntitlementsSummary = () => {
    navigation.navigateTo(appRoutes.subscriptionPage(refId, SubscriptionPageTabs.EntitlementSummary));
  };

  const onSubscriptionUpdate = async (values: SubscriptionFormFields) => {
    if (!customer) {
      return;
    }

    await handleUpdateSubscription(subscription.subscriptionId, values, dispatch);
    setEditSubscriptionDialogOpen(false);
    await dispatch(fetchSubscriptionByRefIdAction({ refId }));
  };

  const onSubscriptionCreate = async (
    subscriptionFormFields: SubscriptionFormFields,
    plan?: PlanListFragment | PlanFragment,
    isAllowEmptyResourceIdEnabled?: boolean,
  ) => {
    if (!customer) {
      return;
    }
    await handleCreateSubscription(
      customer.customerId,
      dispatch,
      subscriptionFormFields,
      plan,
      isAllowEmptyResourceIdEnabled,
    );

    setEditSubscriptionDialogOpen(false);
  };

  const activePlanIds = findSubscriptionsPlansOfStatus(activeSubscriptions, SubscriptionStatus.Active);
  const trialPlanIds = findSubscriptionsPlansOfStatus(activeSubscriptions, SubscriptionStatus.InTrial);

  const tabs = [
    {
      title: t('customers.tabs.overview'),
      url: SubscriptionPageTabs.Overview,
      content: (
        <GridFlex.Column gap={6} flexWrap="nowrap">
          <SubscriptionInfo
            subscription={subscription}
            onMigrateSubscriptionToLatest={() => showMigrateToLatestDialog(true)}
            onCancelSubscriptionClick={() => setCancelSubscriptionDialogOpen(true)}
            onUpdateSubscriptionClick={() => setEditSubscriptionDialogOpen(true)}
            onCancelSubscriptionFutureUpdatesClick={onCancelSubscriptionFutureUpdatesClick}
            onTriggerSubscriptionUsageSync={onTriggerSubscriptionUsageSync}
            onShowEntitlementsSummary={onShowEntitlementsSummary}
            onMarkInvoiceAsPaidClick={() => setMarkInvoiceAsPaidDialogOpen(true)}
          />
          <SubscriptionAddons
            outdatedPricePackages={subscription.outdatedPricePackages}
            addons={subscription.addons || []}
          />
          <EntityMetadataCard
            metaData={subscription.additionalMetaData}
            onSubmit={onSubmitSetMetaData}
            entityTypeName="subscription"
          />
        </GridFlex.Column>
      ),
    },
    {
      title: t('customers.tabs.entitlementUsage'),
      url: SubscriptionPageTabs.EntitlementUsage,
      content: (
        <>
          {customer ? (
            <CustomerEntitlementsUsage
              entitlements={entitlements}
              isLoading={isLoadingEntitlements}
              reloadEntitlementsAction={dispatchFetchEntitlements}
              entitlementsLastUpdated={entitlementsLastUpdated}
              customerRefId={customer.customerId}
              selectedResource={subscription}
            />
          ) : null}
        </>
      ),
    },
    {
      title: t('customers.tabs.entitlementSummary'),
      url: SubscriptionPageTabs.EntitlementSummary,
      content: (
        <CustomerEntitlementsSummary
          entitlements={entitlements}
          isLoading={isLoadingEntitlements || isLoadingSubscription}
          reloadEntitlementsAction={dispatchFetchEntitlements}
          entitlementsLastUpdated={entitlementsLastUpdated}
          activePlanIds={activePlanIds}
          trialPlanIds={trialPlanIds}
          selectedResource={subscription}
        />
      ),
    },
    {
      title: t('customers.tabs.events'),
      url: SubscriptionPageTabs.Activity,
      content: <EventLog entityId={subscription.refId} />,
      hidden: !showEventLog,
    },
  ];

  if (isEmpty(subscription) || !subscription.subscriptionId || isLoadingSubscription) {
    return <Loader />;
  }

  return (
    <GridFlex.Column gap={6} flexWrap="nowrap" $fullHeight>
      <SubscriptionHeader subscription={subscription} />
      <Tabs data={tabs} />
      <MigrateToLatestDialog {...migrateToLatestDialogProps} />
      <CancelScheduledUpdatesDialog {...CancelScheduledUpdatesDialogProps} />
      <Dialog
        content={
          <CancelSubscriptionForm
            subscription={subscription}
            onCancel={() => setCancelSubscriptionDialogOpen(false)}
            customerId={subscription.customer.id}
          />
        }
        titleText={t('subscriptions.cancelSubscriptionDialogTitle')}
        isOpen={cancelSubscriptionDialogOpen}
        onCancel={() => setCancelSubscriptionDialogOpen(false)}
        aria-labelledby="edit-customer-dialog"
      />
      <Dialog
        disableCloseOnEscapeKey
        fullScreen
        padding={0}
        content={
          customer && (
            <SubscriptionForm
              customer={customer}
              onCancel={onCloseSubscriptionForm}
              handleSubmit={createSubscriptionMode ? onSubscriptionCreate : onSubscriptionUpdate}
              subscription={
                createSubscriptionMode ? null : (subscription as unknown as CustomerSubscriptionDataFragment)
              }
              switchToCreateMode={switchToCreateMode}
            />
          )
        }
        isOpen={editSubscriptionDialogOpen}
        onCancel={onCloseSubscriptionForm}
        aria-labelledby="edit-customer-subscription-dialog"
      />
      <MarkInvoiceAsPaidDialog {...markInvoiceAsPaidDialogProps} />
    </GridFlex.Column>
  );
}
