import React from 'react';
import { isEmpty } from 'lodash';
import { DragDropContext, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Box, Paper, Grid, Table as MuiTable, TablePagination, Divider } from '@stigg-components';
import numeral from 'numeral';
import { TableContainer } from './Table.style';
import { TableComponentProps, HeadCell, TableItem } from './Table.types';
import { TableHead, TableBody } from './components';
import { useInfiniteScroll } from '../../modules/common/useInfiniteScroll';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../modules/common/pagination';
import { useCustomTableDndPlaceholder } from '../dragAndDrop';
import { getPaginationContext } from './Table.utils';

const formatNumber = (number: number) => numeral(number).format('0,0');

const Table = <TEntity extends TableItem, TCell extends keyof TEntity>({
  label,
  pageChangeFunc,
  pageNum,
  pageInfo,
  totalCount,
  rowHeight = 65,
  headerHeight,
  withRowHover = true,
  tableLayout = 'fixed',
  headlessTable = false,
  backgroundColor,
  headCells,
  data,
  collapsableComponentOptions,
  headerComponent,
  onRowClick,
  rowColor,
  headerRowColor,
  isHeaderComponentChild,
  headerComponentOptions = { backgroundColorActive: false },
  hasBorder = true,
  shouldHighlightRow,
  highlighDelay,
  disable,
  enableRowSelection,
  hideHeaderSelection,
  onSelectAllToggle,
  onItemSelectionToggle,
  maxHeight,
  isLoading,
  skeletonOptions,
  infiniteScrollOptions,
  stickyHeader,
  isRowDisabled,
  isCellDisabled,
  disableBoldHeader,
  readonly,
  onDragEnd,
  withDragHandle,
  withInnerDividers,
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
  rowsPerPageOptions,
  onPageSizeChange,
}: TableComponentProps<TEntity, TCell>) => {
  const isDraggingEnabled = !readonly && !!onDragEnd;
  const isAllRowsSelected = enableRowSelection && data.every((item) => item.isSelected);
  const isAnyRowSelected = enableRowSelection && data.some((item) => item.isSelected);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelectAllToggle) {
      onSelectAllToggle(event.target.checked);
    }
  };

  const onToggleItemSelection = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (onItemSelectionToggle) {
      onItemSelectionToggle(index, event.target.checked);
    }
  };

  const { scrollContainerRef, loading } = useInfiniteScroll({ ...infiniteScrollOptions });

  const { placeholderProps, handleDragStart, handleDragUpdate, handleDragEnd } = useCustomTableDndPlaceholder(
    headerComponent ? 1 : 0,
  );

  const renderTable = (provided?: DroppableProvided, snapshot?: DroppableStateSnapshot) => {
    const { innerRef, placeholder, droppableProps = {} } = provided || {};
    const { isDraggingOver } = snapshot || {};

    const headerCells = headCells.filter(({ inNewRow }) => !inNewRow);
    const cellsInNewRow = headCells.filter(({ inNewRow }) => inNewRow);

    const paginationContext = getPaginationContext({ totalCount, pageNum, pageSize, data, pageInfo });

    return (
      <Grid $fullWidth ref={innerRef} {...droppableProps}>
        <Paper
          sx={{
            overflow: 'hidden',
            backgroundColor: (theme) =>
              theme.isLightTheme ? backgroundColor : backgroundColor || theme.itamar.palette.background.darkBackground,
            border: !hasBorder ? 'none' : (theme) => theme.itamar.border.border,
            borderColor: !hasBorder ? 'none' : (theme) => theme.itamar.border.borderColor,
            borderRadius: !hasBorder ? '0' : (theme) => theme.itamar.border.radius,
          }}>
          <TableContainer
            sx={{ maxHeight: maxHeight || 'none' }}
            ref={scrollContainerRef}
            $withInnerDividers={withInnerDividers}>
            <MuiTable stickyHeader={stickyHeader} sx={{ tableLayout }} aria-labelledby={label} size="medium">
              {!headlessTable && (
                <TableHead
                  headerHeight={headerHeight}
                  headCells={headerCells}
                  collapsableComponentOptions={collapsableComponentOptions}
                  headerRowColor={headerRowColor}
                  enableRowSelection={enableRowSelection}
                  hideHeaderSelection={hideHeaderSelection}
                  isAllRowsSelected={isAllRowsSelected}
                  isAnyRowSelected={isAnyRowSelected}
                  handleSelectAllClick={handleSelectAllClick}
                  disableBoldHeader={disableBoldHeader}
                  isDraggingEnabled={isDraggingEnabled || withDragHandle}
                />
              )}
              <TableBody
                headCells={headerCells}
                cellsInNewRow={cellsInNewRow}
                headerComponent={headerComponent}
                headerComponentOptions={headerComponentOptions}
                rowHeight={rowHeight}
                withRowHover={withRowHover}
                data={data}
                collapsableComponentOptions={collapsableComponentOptions}
                onRowClick={onRowClick}
                rowColor={rowColor}
                isHeaderComponentChild={isHeaderComponentChild}
                hasBorder={hasBorder}
                shouldHighlightRow={shouldHighlightRow}
                highlighDelay={highlighDelay}
                disable={disable}
                enableRowSelection={enableRowSelection}
                onToggleItemSelection={onToggleItemSelection}
                infiniteScrollOptions={infiniteScrollOptions}
                isLoading={isLoading}
                skeletonOptions={skeletonOptions}
                isRowDisabled={isRowDisabled}
                isCellDisabled={isCellDisabled}
                isLoadingMoreData={loading}
                isDraggingEnabled={isDraggingEnabled}
                withDragHandle={withDragHandle}
                placeholder={
                  <>
                    {placeholder}
                    {!isEmpty(placeholderProps) && isDraggingOver && (
                      <Box
                        sx={{
                          top: placeholderProps.clientY,
                          left: placeholderProps.clientX,
                          height: placeholderProps.clientHeight ? placeholderProps.clientHeight - 2 : undefined,
                          width: placeholderProps.clientWidth,
                          position: 'absolute',
                          backgroundColor: (theme) =>
                            theme.isLightTheme
                              ? theme.itamar.palette.background.lightBackground
                              : theme.itamar.palette.background.darkBackground,
                          marginTop: '2px',
                        }}
                      />
                    )}
                  </>
                }
              />
            </MuiTable>
          </TableContainer>
          {pageChangeFunc && paginationContext ? (
            <>
              <Divider />
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions ?? [pageSize]}
                onRowsPerPageChange={(e) => onPageSizeChange?.(parseInt(e.target.value, 10))}
                component="div"
                count={paginationContext.totalCount}
                nextIconButtonProps={paginationContext.nextIconButtonProps}
                rowsPerPage={pageSize}
                page={pageNum || 0}
                onPageChange={pageChangeFunc}
                sx={{ color: (theme) => theme.itamar.palette.text.primary }}
                labelDisplayedRows={({ from, to, count }) =>
                  `${formatNumber(from)}-${formatNumber(to)} of ${
                    count !== -1 ? formatNumber(count) : `more than ${formatNumber(to)}`
                  }`
                }
              />
            </>
          ) : null}
        </Paper>
      </Grid>
    );
  };

  if (isDraggingEnabled) {
    return (
      <DragDropContext
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
        onDragEnd={(...args) => {
          handleDragEnd(...args);

          const [{ source, destination }] = args;
          if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
          }

          onDragEnd(...args);
        }}>
        <Droppable droppableId="table-droppable-id">
          {(provided, snapshot) => renderTable(provided, snapshot)}
        </Droppable>
      </DragDropContext>
    );
  }

  return renderTable();
};

export default Table;

export type { HeadCell };
