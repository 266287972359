import { Components } from '@mui/material/styles/components';

import typography from './typography';
import { VariantType } from './variants.types';

export const getComponents = (variant: VariantType): Components => ({
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-input.Mui-disabled': {
          color: variant.itamar.palette.text.disabled,
          '-webkit-text-fill-color': variant.itamar.palette.text.disabled,
        },
        radius: '10px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${variant.itamar.palette.other.outlineBorder}`,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: `${variant.itamar.palette.primary.main}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: `${variant.itamar.palette.primary.main}`,
        },
        '&.Mui-disabled': {
          color: 'red',
          backgroundColor: variant.itamar.palette.grey[50],
        },
        color: 'palette.other.outlineBorder',
        transition: '0.1s',
        ...typography.body2,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      thumb: {
        // todo:fix this #colors
        // boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow,
        boxShadow: '0 0 10px #001E6C1f',
      },
    },
  },
});
