import { GridFlex } from '@stigg-components';
import { styled } from '@stigg-theme';

export const WrapperGridFlexRowSpaceBetween = styled(GridFlex.RowSpaceBetween)<{ $isHidden?: boolean }>`
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(5)};
  height: 60px;
  border-left: 3px solid ${({ theme }) => theme.itamar.palette.primary.main};
  background-color: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.grey[100] : theme.itamar.palette.background.darkBackground5};

  box-shadow: inset 0 -1px ${({ theme }) => theme.itamar.palette.shadow.inset};
  border-radius: ${({ theme }) => `0 ${theme.itamar.border.radius} ${theme.itamar.border.radius} 0`};
  opacity: ${({ $isHidden }) => ($isHidden ? 0.5 : 1)};
`;
